import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import useToast from '../../../components/Toast/hooks/useToast';
import WinRate from './LeadEstimates/WinRate';
import ImportantMetrix from './LeadEstimates/ImportantMetrix';
import PredictiveScoringEndpoints from '../PredictiveScoringEndpoints';
import useApiService from '../../../services/api.service';
import { setDataforAccountScoring } from './LeadEstimates/LeadScoringData';
import GradeEstimatesforAccountScoring from './AccountScoring/GradeEstimates';

const AccountScoring: React.FC<any> = (props: any) => {
	const theme: any = useTheme();
	const toaster: any = useToast();
	const APIService: any = useApiService();
	const [winRateData, setWinRateData] = useState();
	const [gradeEstimatesData, setGradeEstimatesData] = useState();
	const [isLoading, setisLoading]: any = useState(true);
	const [selectedGradeType, setSelectedGradeType] = useState('overall');
	const [ImportantMetrixData, setImportantMetrixData]: any = useState();
	const getSelectedGradeType = (gradeType: any) => {
		setSelectedGradeType(gradeType);
	};
	const getLeadScoringData = async () => {
		try {
			setisLoading(true);
			const request = {
				reqBody: {
					level: 3,
					offset: 1,
					limit: 10,
					start_date: props.dateRange.start_date,
					end_date: props.dateRange.end_date,
					stage: "All"
				},
			};
			const res = await APIService.post(
				PredictiveScoringEndpoints.leadScoring(request),
				true
			);
			setData(res);
		} catch (err) {
			toaster.addToast({
				message: 'Kindly, enable Train Model',
				timeout: 3000,
				type: 'error',
			});
			console.error(err);
		} finally {
			setisLoading(false);
		}
	};
	useEffect(() => {
		getLeadScoringData();
	}, [props?.dateRange]);

	const setData = (res: any) => {
		setWinRateData(res?.average);
		setGradeEstimatesData(res?.lead_score);
		setImportantMetrixData(res?.analysis_dict);
	};

	return (
		<>
			<Grid
				container
				className='tab_container'
				style={{ marginBottom: '50px' }}
			>
				<WinRate
					setData={setDataforAccountScoring}
					Data={winRateData}
					isLoading={isLoading}
					onGradeTypeChange={getSelectedGradeType}
				/>
				<GradeEstimatesforAccountScoring
					Data={gradeEstimatesData}
					grade={selectedGradeType}
					isLoading={isLoading}
				/>
				<ImportantMetrix Data={ImportantMetrixData} isLoading={isLoading} />
			</Grid>
		</>
	);
};

export default React.memo(AccountScoring);
