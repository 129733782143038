import LeadScoring from '../../assets/Images/Sidebar/LeadScoringIcon.svg';
import OpportunityScoring from '../../assets/Images/Sidebar/OppurtunityScoring.svg';
import AccountScoring from '../../assets/Images/Sidebar/AccountScoringIcon.svg';
import HumanVsAI from '../../assets/Images/Sidebar/HumanAI.svg';
import AIInsights from '../../assets/Images/Sidebar/AIInsights.svg';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import BudgetAllocation from '../../assets/Images/Sidebar/budgetAllocation.svg';
import DataInsights from '../../assets/Images/Sidebar/DataInsights.svg';
import HistoricalInsights from '../../assets/Images/Sidebar/HistoricalInsights.svg';

export const MMMItems = [
    {
        label: 'Historical Insights',
        icon: <img src={HistoricalInsights} alt='Data Enrichment' style={{ fontSize: 'inherit' }} />,
        level: 0,
    },
    {
        label: 'Data Insights',
        icon: <img src={DataInsights} alt='Data Enrichment' style={{ fontSize: 'inherit' }} />,
        level: 1,
    },
    {
        label: 'Budget Allocation',
        icon: <img src={BudgetAllocation} alt='Data Enrichment' style={{ fontSize: 'inherit' }} />,
        level: 2,
    },
];

export const predictiveScoringItems = [
    {
        label: 'Lead Scoring',
        icon: <img src={LeadScoring} alt='Data Enrichment' style={{ fontSize: 'inherit' }} />,
        onClick: 0,
    },
    {
        label: 'Opportunity Scoring',
        icon: <img src={OpportunityScoring} alt='Data Enrichment' style={{ fontSize: 'inherit' }} />,
        onClick: 1,
    },
    {
        label: 'Account Scoring',
        icon: <img src={AccountScoring} alt='Data Enrichment' style={{ fontSize: 'inherit' }} />,
        onClick: 2,
    },
    {
        label: 'Human vs AI',
        icon: <img src={HumanVsAI} alt='Data Enrichment' style={{ fontSize: 'inherit' }} />,
        onClick: 3,
    },
    {
        label: 'AI Insights',
        icon: <img src={AIInsights} alt='Data Enrichment' style={{ fontSize: 'inherit' }} />,
        onClick: 4,
    },
];

export const DateRangePaths = [
	'/insights/chatbot',
	'/insights/predictive-scoring',
	'/insights/Media-Mix-Modelling',
	'/insights/Data-Enrichment',
];

export const secondSidebarPaths = [
	'/insights/chatbot', // Diggi-GPT
	'/insights/predictive-scoring', // Predictive Scoring
	'/insights/Media-Mix-Modelling', // MMM
];

export const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}));

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const drawerWidth = 80
export const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	transition: theme.transitions.create(['margin', 'width']),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(['margin', 'width']),
	}),
}));