import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import DataTable from '../../../../components/DataTable/DataTable';
import { useEffect, useState } from 'react';
import { TableColumnsforAccountScoring } from '../LeadEstimates/LeadScoringData';
import '../LeadEstimates/ImportantMetrics.scss';
import CustomSkeleton from '../../../../components/SkeletonLoading/SkeletonLoading';

const GradeEstimatesforAccountScoring = (props: any, ref: any) => {
	const theme: any = useTheme();
	const [Data, setData]: any = useState(props?.Data?.A?.lead_score_dict);

	const getDataForGrade = (grade: string) => {
		switch (grade) {
			case 'grade a':
				return props?.Data?.A?.lead_score_dict;
			case 'grade b':
				return props?.Data?.B?.lead_score_dict;
			case 'grade c':
				return props?.Data?.C?.lead_score_dict;
			case 'overall':
				return props?.Data?.lead_score_dict;
			default:
				return null;
		}
	};

	useEffect(() => {
		setData(getDataForGrade(props?.grade));
	}, [props]);
	return (
		<>
			<div className='reportHeading'>
				<Typography variant='h4' component='span'>
					Grade Estimates
				</Typography>
			</div>
			<Grid
				container
				style={{
					padding: '1rem',
					boxShadow: 'none',
					backgroundColor: theme.palette.mode == 'dark' ? '#1A1A1A' : '#F4F7FC',
					borderRadius: '20px',
				}}
			>
				{props?.isLoading ? (
					<CustomSkeleton
						variant={'rectangular'}
						height={500}
						width={'100%'}
						style={{ borderRadius: '20px' }}
					/>
				) : (
					<Grid
						xs={12}
						style={{
							backgroundColor: theme.palette.mode == 'dark' ? 'black' : 'white',
							borderRadius: '20px',
						}}
					>
						<div className='grade_Estimates_Table'>
							<DataTable
								defineMargin={'1rem'}
								data={Data}
								columns={TableColumnsforAccountScoring}
								showPagination
							/>
						</div>
					</Grid>
				)}
			</Grid>
		</>
	);
};

export default GradeEstimatesforAccountScoring;
